import { WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

interface StoreParams<T> {
  name: string;
  initialState: T;
}

export abstract class Store<T> {
  readonly #state: WritableSignal<T>;
  protected readonly state$: Observable<T>;
  readonly name: string;

  protected constructor({ name, initialState }: StoreParams<T>) {
    this.name = name;
    this.#state = signal(initialState);
    this.state$ = toObservable(this.#state);
  }

  getValue(): Readonly<T> {
    return this.#state();
  }

  protected update(updateFn: (value: T) => T): void {
    this.#state.update(updateFn);
  }
}
